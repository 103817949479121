


import { Vue, Component } from 'vue-property-decorator';
import {Action, Getter} from 'vuex-class';
import { RawLocation } from 'vue-router';

@Component
export default class AuthPage extends Vue {

  @Getter('authStore/isAuthenticated') isAuthenticated: boolean;
  @Action('authStore/authLogout') logout: () => void;

  public get isLogoutPage(): boolean {
    return this.$route.name === 'auth-page-logout';
  }

  public created(): void {
    if (this.isLogoutPage) {
      this.onLogoutPageCreated();
      return;
    }
    this.onAuthPageCreated();
  }

  public onAuthPageCreated(): void {
    this.isAuthenticated ? this.onAlreadyAuthenticated() : this.showAuthPopup();
  }

  public async onLogoutPageCreated(): Promise<void> {
    if (!this.isAuthenticated) {
      await this.$router.push({ name: 'auth-page' });
      this.onAuthPageCreated();
      return;
    }

    await this.logout();
    try {
      window.location.href = window.location.origin;
    } catch {
      // ignore
    }
  }

  public onAlreadyAuthenticated(): void {
    this.$router.push(this.getTargetRoute());
    this.hideAuthPopup();
  }

  public async hideAuthPopup(): Promise<void> {
    await this.$store.dispatch('authStore/setAuthPopupTargetRoute', null);
    await this.$store.dispatch('authStore/setStandaloneAuthVisibility', false);
  }

  public async showAuthPopup(): Promise<void> {
    await this.$store.dispatch('authStore/setAuthPopupTargetRoute', null);
    await this.$store.dispatch('authStore/setStandaloneAuthVisibility', true);
  }

  public getTargetRoute(): RawLocation {
    if (!this.isQueryEventPresent()) {
      return {
        name: 'event-list-my',
      };
    }
    return {
      name: 'promo-live',
      params: {
        eventId: this.$route.query.event as string,
      }
    };
  }

  public isQueryEventPresent(): boolean {
    if (!this.$route || this.$route.query || !this.$route.query.event || Array.isArray(this.$route.query.event)) {
      return false;
    }
    return /^\d+$/.test(this.$route.query.event);
  }
}
